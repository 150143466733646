import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import RelatedArticles from '../components/relatedArticles'
import Card from '../components/card'
import SectionContainer from '../components/layout/sectionContainer'
import Cta from '../components/common/cta'
import * as articleCategoryStyling from './articleCategory.module.scss'

export default function ArticleCategoryPage({ data, pageContext, location }) {
    const { contentfulArticleCategory } = data

    const articles = contentfulArticleCategory.article

    if (articles === null) {
        return null
    }

    const sortedArticles = articles.sort((a, b) => {
        return new Date(b.createdAtRaw) - new Date(a.createdAtRaw)
    })

    return (
        <>
            <GatsbySeo
                title={contentfulArticleCategory.title}
                description={contentfulArticleCategory.description.description}
            />
            <Hero
                heading={contentfulArticleCategory.title}
                body={contentfulArticleCategory.description.description}
            />
            <SectionContainer title={'Artiklar i kategorin'}>
                {sortedArticles &&
                    sortedArticles.map((article) => (
                        <Card
                            key={article.id}
                            bodyText={article.subtitle}
                            image={article.image}
                            title={article.title}
                            subtitle={article.createdAt}
                            to={article.slug}
                            showBanner={article.quiz}
                            bannerText={'Med quiz'}
                        />
                    ))}
            </SectionContainer>
            <div className={articleCategoryStyling.showAllLink}>
                <Cta isLink={true} to="/kategorier" text="Se alla kategorier" />
            </div>
            {contentfulArticleCategory && (
                <RelatedArticles
                    currentCategory={contentfulArticleCategory}
                    headline="Från andra kategorier"
                />
            )}
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulArticleCategory(title: { eq: $title }) {
            id
            title
            description {
                description
            }
            image {
                title
                gatsbyImageData(
                    width: 1920
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
            mobileImage {
                gatsbyImageData(
                    width: 800
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
            article {
                slug
                id
                title
                createdAtRaw: createdAt
                createdAt(formatString: "D MMMM YYYY", locale: "sv")
                subtitle
                quiz {
                    id
                }
                image {
                    title
                    gatsbyImageData(
                        width: 848
                        height: 548
                        layout: CONSTRAINED
                        placeholder: BLURRED
                    )
                }
            }
        }
    }
`
